@if (showTitle && title) {
    <div class="grid grid-cols-12 mb-0 gap-x-6">
        <div
            [ngClass]="{
                'col-span-12': true,
                'lg:col-span-8': items.length === 1 && indentList,
                'lg:col-start-2': items.length === 1 && indentList,
                'text-center': hasIcon,
            }">
            <h3 [class]="titleClass">{{ title }}</h3>
        </div>
    </div>
}

<ul
    class="grid grid-cols-12 mb-0 mb-4 gap-x-6"
    [class.mt-2]="!(showTitle && title)"
    [class.-mt-4]="showTitle && title">
    @for (item of items | listBreakpointSort: !isDesktop(); track item; let i = $index; let isFirst = $first; let count = $count) {
        <li
            [ngClass]="{
                'col-span-12': true,
                'lg:col-start-3': count === 1 && i % 2 === 0 && !hasIcon && indentList,
                'lg:col-start-2': i % 2 === 0 && hasIcon && indentList,
                'lg:col-span-10': count === 1 && hasIcon && extendSingleItem,
                'lg:col-span-8': count === 1 && !hasIcon && indentList && extendSingleItem,
                'lg:col-span-6': (count > 1 && !hasIcon) || (!extendSingleItem && !hasIcon),
                'md:col-span-6': (count > 1 && indentList) || (!extendSingleItem && !indentList),
                'lg:col-span-5': count > 1 && hasIcon,
            }">
            <big-ui-link-list-item
                [item]="item"
                [canGrow]="isFirst"
                [noPadding]="0 === i || 1 === i"
                [parentCount]="count"
                class="h-full" />
        </li>
    }
</ul>
