import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, type OnInit } from '@angular/core';
import { type SafeHtml } from '@angular/platform-browser';
import { BreakpointService, ListBreakpointSortPipe, type LinkListItem } from '@big-direkt/utils/shared';
import { LinkListItemComponent } from '../link-list-item/link-list-item.component';

@Component({
    selector: 'big-ui-link-list',
    templateUrl: './link-list.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [LinkListItemComponent, ListBreakpointSortPipe, NgClass],
})
export class LinkListComponent implements OnInit {
    public readonly breakpointService = inject(BreakpointService);

    @Input() public title?: string;
    @Input() public showTitle = true;
    @Input() public titleClass = 'mb-6 h4 text-break';
    @Input() public items: LinkListItem[] = [];
    @Input() public indentList = true;
    @Input() public extendSingleItem = true;

    @HostBinding('class') public classList = 'block';

    public isDesktop = this.breakpointService.isDesktop;
    public hasIcon = false;

    public ngOnInit(): void {
        this.hasIcon = this.items.some((item: LinkListItem): SafeHtml | undefined => item.icon);
    }
}
