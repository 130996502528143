<ng-container>
    <div
        class="{{ spinnerClass }}"
        [ngClass]="{ 'text-primary': color === 'primary', 'text-white': color === 'white' }">
        <div
            class="relative flex items-center justify-center w-[4em] h-[4em] before:animate-spinner before:content-[''] before:rounded-full before:h-[1em] before:w-[1em]"
            [ngClass]="{
                'text-[8px]': size === 'm',
                'text-[5px]': size === 's'
            }"
            role="status"
            aria-busy="true"></div>
        <div
            #content
            [ngClass]="{ 'ml-2': content.hasChildNodes() }">
            <ng-content />
        </div>
    </div>
</ng-container>
