import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinnerComponent } from './components/spinner.component';

@NgModule({
    imports: [NgClass],
    declarations: [SpinnerComponent],
    exports: [SpinnerComponent],
})
export class UiSpinnerModule {
}
